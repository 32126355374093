import React, { useContext } from "react"
import { Helmet } from "react-helmet"
import { Link } from "gatsby"
import styled from "styled-components"

import { CurrentUserContext } from "../../context/currentUser"
import Hero, { HeroContent } from "../hero"
import NotAuthorized from "./elements/NotAuthorized"

const Default = () => {
  const [{ isLoggedIn }] = useContext(CurrentUserContext)

  const breadcrumbList = [
    { to: "/", name: "Главная" },
    { to: "/dashboard", name: "Dashboard" },
  ]

  if (!isLoggedIn) {
    return (
      <Page>
        <Helmet title="Панель управления" defer={false} />
        <Hero breadcrumbs={breadcrumbList} backLink="/">
          <HeroContent>
            <h1 className="heading">Панель управления</h1>
          </HeroContent>
        </Hero>
        <NotAuthorized />
      </Page>
    )
  }

  return (
    <Page>
      <Helmet title="Панель управления" defer={false} />

      <Hero breadcrumbs={breadcrumbList} backLink="/">
        <HeroContent>
          <h1 className="heading">Панель управления</h1>
        </HeroContent>
      </Hero>

      <PageContent>
        <nav className="nav">
          <Link
            className="nav__link"
            to="/dashboard/profile"
            activeClassName="active"
          >
            Profile
          </Link>
        </nav>
      </PageContent>
    </Page>
  )
}

export default Default

const Page = styled.div`
  padding-top: 0;
`

const PageContent = styled.section`
  padding-left: var(--offset-one-side);
  padding-right: var(--offset-one-side);
  margin-bottom: 0.5em;
`
