import React, { useContext } from "react"
import styled from "styled-components"

import { CurrentUserContext } from "../../context/currentUser"

const Profile = () => {
  const [{ currentUser }] = useContext(CurrentUserContext)

  console.log("Profile", currentUser)

  return (
    <Page>
      <PageHader>
        <h1 className="heading">Profile</h1>
      </PageHader>

      <UserData>
        <p>{currentUser.name}</p>
        <p>{currentUser.email}</p>
      </UserData>
    </Page>
  )
}

export default Profile

const Page = styled.div`
  padding-top: 100px;
`

const PageHader = styled.header`
  padding-left: var(--offset-one-side);
  padding-right: var(--offset-one-side);
  margin-bottom: 0.5em;
`

const UserData = styled.section`
  padding: 2rem var(--offset-one-side);
`
