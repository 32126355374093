import React, { useEffect, useState } from "react"
import styled from "styled-components"
import classNames from "classnames"

const InputText = ({
  name,
  label,
  value,
  onChange,
  error,
  type = "text",
  placeholder = "",
  autoComplete = "off",
  required = false,
}) => {
  const inputId = `input-${name}`
  const [message, setMessage] = useState(null)
  const [focus, setFocus] = useState(false)

  const handleFocus = () => {
    setFocus(true)
  }
  const handleBlur = () => {
    setFocus(false)
  }

  useEffect(() => {
    setMessage((error && error[name]) || null)
  }, [error, name])

  const fieldsetClass = classNames({
    focus,
    active: value && value.length > 0,
    "--error": message,
  })

  const labelClass = classNames({
    label: true,
    required,
  })

  const inputClass = classNames({
    input: true,
    "--error": message,
  })

  return (
    <Fieldset className={fieldsetClass}>
      <label className={labelClass} htmlFor={inputId}>
        {label && <span className="label__name">{label}</span>}
        <input
          id={inputId}
          type={type}
          name={name}
          className={inputClass}
          placeholder={placeholder}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onChange={onChange}
          autoComplete={autoComplete}
        />
      </label>
      {message && <p className="message --error">{message}</p>}
    </Fieldset>
  )
}

export default InputText

const Fieldset = styled.fieldset`
  margin-bottom: 1rem;
  .message {
    font-size: 0.9rem;
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .label {
    --label-background: var(--color-surface);
    --label-border-color: var(--active-color, var(--label-background));
    display: flex;
    flex-direction: column;
    padding: 1rem;
    background: var(--label-background);
    border: 1px solid;
    border-color: var(--label-border-color);
    /* border-radius: 0.5rem; */
    cursor: text;
    &.required .label__name:after {
      content: "*";
      color: var(--color-danger);
      display: inline-block;
      padding-left: 3px;
    }
    .input {
      padding: 0.5rem 0;
      font-size: 1.25rem;
      line-height: 1;
      box-shadow: none;
      background: transparent;
      border-color: transparent;
      color: currentColor;
    }
  }
  &.focus .label {
    box-shadow: 0 0 0 4px rgba(var(--color-primary-rgb), 0.1);
    border-color: var(--color-primary);
  }
  .label__name {
    font-family: var(--headingfont);
    font-size: 0.85rem;
    font-weight: 500;
    line-height: 1;
  }
`
