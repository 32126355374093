import React, { useContext } from "react"
import { navigate } from "gatsby"
import { CurrentUserContext } from "../../context/currentUser"

const PrivateRoute = ({ component: Component, location, ...rest }) => {
  const [{ isLoggedIn }] = useContext(CurrentUserContext)

  if (!isLoggedIn && location.pathname !== `/dashboard/login`) {
    navigate("/dashboard/login")
    return null
  }

  return <Component {...rest} />
}
export default PrivateRoute
