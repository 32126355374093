import { useEffect } from "react"
import { loader } from "graphql.macro"
import { useMutation } from "@apollo/react-hooks"
import { useForm } from "../useForm"
import { useBackendErrors } from "../useBackendErrors"

const gql = loader("./gql/mutationLogin.graphql")

export const useLogin = () => {
  const [trigger, { loading, error: backendError, data }] = useMutation(gql)
  const [error, setError] = useBackendErrors()

  const [input, handleChange] = useForm({
    email: "",
    password: "",
  })

  useEffect(() => {
    // console.log("useEffect(backendError)", backendError)
    if (!backendError) return
    setError(backendError)
  }, [backendError, setError])

  const logIn = event => {
    event.preventDefault()
    ;(async () => {
      try {
        setError(null)
        await trigger({ variables: { input } })
      } catch (error) {
        // console.log("useLogin(error)", error.message)
      }
    })()
  }

  const response = data ? data.login.data : null
  return [{ input, handleChange, loading, error, response }, logIn]
}
