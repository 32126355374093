import React from "react"
import { Router } from "@reach/router"

import LayoutDefault from "../layouts/default"
import Default from "../components/dashboard/Default"
import Profile from "../components/dashboard/Profile"
import Authentication from "../components/dashboard/Authentication"
import PrivateRoute from "../components/dashboard/PrivateRoute"

const Dashboard = () => {
  return (
    <LayoutDefault>
      <Router basepath="/dashboard">
        <Authentication path="/login" />
        <Authentication path="/join" />
        <PrivateRoute path="/profile" component={Profile} />
        <Default path="/" />
      </Router>
    </LayoutDefault>
  )
}

export default Dashboard
