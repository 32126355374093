import { useState, useCallback } from "react"

const initialState = {
  message: null,
  details: null,
}

export const useBackendErrors = () => {
  const [error, setError] = useState(initialState)

  const formatErrors = (obj, { property, message }) => ({
    ...obj,
    [property]: message,
  })

  const onError = useCallback(backendError => {
    // console.log("onError(backendError)", backendError)
    if (!backendError) {
      setError({ ...initialState })
    } else if (typeof backendError === "string") {
      setError({ ...initialState, message: backendError })
    } else {
      const { data, message } = backendError.graphQLErrors[0]
      setError({
        ...initialState,
        message: message || null,
        details: data ? data.errors.reduce(formatErrors, {}) : null,
      })
    }
  }, [])

  return [error, onError]
}
