import React from "react"
import { Link } from "gatsby"
import styled from "styled-components"

const NotAuthorized = () => {
  return (
    <NotAuthorizedWrapper>
      <h2 className="heading">Вы не авторизованы</h2>
      <p>Чтобы просматривать содержимое этой страницы необходимо войти.</p>
      <div className="row">
        <Link className="auth__link" to="/dashboard/login">
          Войти
        </Link>

        <span> / </span>

        <Link className="auth__link" to="/dashboard/join">
          Зарегистрироваться
        </Link>
      </div>
    </NotAuthorizedWrapper>
  )
}

export default NotAuthorized

const NotAuthorizedWrapper = styled.div`
  padding-left: var(--offset-one-side);
  padding-right: var(--offset-one-side);
  > p {
    margin-bottom: 1rem;
  }
  .auth__link {
    margin: 0.5rem var(--offset-one-side);
  }
`
