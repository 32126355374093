import React, { useState, useEffect, useContext } from "react"
import { Helmet } from "react-helmet"
import { Link, navigate } from "gatsby"
import styled from "styled-components"

import { CurrentUserContext } from "../../context/currentUser"

import { useLogin } from "../../hooks/useLogin"
import { useSignup } from "../../hooks/useSignup"
import useLocalStorage from "../../hooks/useLocalStorage"

import InputText from "../elements/form/InputText"
import Button from "../elements/Button"

const Authentication = props => {
  const isLogin = props.path === "/login"
  const pageTitle = isLogin ? "Авторизация" : "Регистрация"
  const descLink = isLogin ? "/dashboard/join" : "/dashboard/login"
  const descText = isLogin ? "Нет аккаунта?" : "Уже есть аккаунт?"
  const useHook = isLogin ? useLogin : useSignup

  const [
    { input, handleChange, loading, error, response },
    handleSubmit,
  ] = useHook()

  const [isSuccessfulSubmit, setIsSuccessfulSubmit] = useState(false)
  const [, setToken] = useLocalStorage("token")
  const [, dispatch] = useContext(CurrentUserContext)

  useEffect(() => {
    if (!response) return
    setToken(response.accessToken)
    dispatch({ type: "SET_AUTHORIZED", payload: response.user })
    setIsSuccessfulSubmit(true)
  }, [response, setToken, dispatch])

  if (isSuccessfulSubmit) {
    navigate("/dashboard")
    return null
  }

  const errorJSX = error.message && (
    <p className="message --error">{error.message}</p>
  )

  return (
    <Page>
      <Helmet title={pageTitle} defer={false} />
      <PageHader>
        <h1 className="heading">{pageTitle}</h1>
        <p className="subtitle">
          <Link to={descLink}>{descText}</Link>
        </p>
      </PageHader>

      <div className="container">
        {errorJSX}
        <form className="form" onSubmit={handleSubmit}>
          {!isLogin && (
            <InputText
              name="name"
              label="Ваше имя"
              value={input.name}
              onChange={handleChange}
              error={error.details}
            />
          )}

          <InputText
            type="email"
            name="email"
            label="Email"
            autoComplete="email"
            value={input.email}
            onChange={handleChange}
            error={error.details}
            required
          />

          <InputText
            type="password"
            name="password"
            label="Пароль"
            autoComplete="current-password"
            value={input.password}
            onChange={handleChange}
            error={error.details}
            required
          />

          <Button type="submit" text="Войти" loading={loading} primary />
        </form>
      </div>
    </Page>
  )
}

export default Authentication

const Page = styled.div`
  padding-top: 100px;
`

const PageHader = styled.header`
  padding-left: var(--offset-one-side);
  padding-right: var(--offset-one-side);
  margin-bottom: 0.5em;
`
